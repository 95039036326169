<template>
  <v-container class="fill-height" fluid>
    <template>
      <v-row class="mb-6" no-gutters>
        <v-col md="12">
          <v-card class="elevation-2 mt-6 mx-3 grey lighten-5">
            <v-card-title class="headline display-1">
              Tutoriels
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('tableStatus.searchName')"
                single-line
                hide-details
                class="mt-n2"
              >
              </v-text-field>
            </v-card-title>
            <v-row v-if="isMobile" class="ml-3 mt-n2">
              <i>cliquez sur le document pour l'ouvrir</i>
            </v-row>
            <doc-list :docList="docList" :loadingDoc="loadingDoc"></doc-list>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { UserSessionData } from "../../mixins/userData";
import USER_API from "../../api/user";
import DocList from "./DocList.vue";
import moment from "../../plugins/moment";

export default {
  name: "Tuto",
  data() {
    return {
      loadingDoc: false,
      documentsList: [],
      search: "",
      dateRange: {}
    };
  },
  components: {
    DocList
  },
  mixins: [UserSessionData],
  computed: {
    filteredDocList() {
      if (this.documentsList) {
        return this.documentsList.filter(doc => {
          if (doc.documentcategories_id === 0) {
            doc.documentcategories_id = this.$t("labels.no_category");
          }
          const dateCreation = moment(
            doc.date_creation,
            "YYYY-MM-Do HH:mm ss"
          ).format("YYYY-MM-DD");

          return this.filterByDate(dateCreation);
        });
      } else return this.documentsList;
    },
    docList() {
      if (!this.search) return this.filteredDocList;
      return this.filteredDocList.filter(doc => {
        if (doc.documentcategories_id === 0)
          doc.documentcategories_id = this.$t("labels.no_category");
        return (
          doc.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          doc.documentcategories_id
            .toLowerCase()
            .search(this.search.toLowerCase()) > -1
        );
      });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    ...mapActions(["loadEntity", "loadUserProfile", "loadEntities"]),
    loadData() {
      const supportCat = 25;
      USER_API.loadSpecificDoc(supportCat)
        .then(response => {
          const docs = response.data;
          this.documentsList = docs;
        })
        .finally(() => {
          this.loadingDoc = false;
        });
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
