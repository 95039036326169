<template>
  <v-row justify="center">
    <v-col v-if="isMobile" cols="12">
      <v-list style="max-height: 640px; overflow: auto">
        <v-list-item-group color="primary">
          <template v-if="loadingDoc">
            <v-list-item v-for="(item, i) in 2" :key="i">
              <v-list-item-content>
                <v-skeleton-loader
                  ref="skeleton"
                  type="list-item-avatar-two-line"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item v-for="item in docList" :key="item.id">
              <v-list-item-avatar>
                <v-icon large color="primary">
                  {{ documentIcon(item.mime) }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name"
                  @click="openFile(item)"
                  title="Ouvrir le document dans une nouvelle fenêtre"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.comment"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-text="
                    moment(item.date_creation, 'YYYY-MM-Do HH:mm ss').format(
                      ' Do MMM YYYY, HH:mm'
                    )
                  "
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="downloadFile(item)"
                  :title="$t('assetDetails.text3')"
                >
                  <v-icon dark>mdi-cloud-download-outline</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col v-else cols="12">
      <v-list style="max-height: 640px; overflow: auto">
        <template v-if="loadingDoc">
          <v-list-item v-for="(item, i) in 2" :key="i">
            <v-list-item-content>
              <v-skeleton-loader
                ref="skeleton"
                type="list-item-avatar-two-line"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item v-for="item in docList" :key="item.id">
            <template v-slot:default="{ active }">
              <v-list-item-avatar>
                <v-icon large color="primary">
                  {{ documentIcon(item.mime) }}
                </v-icon>
              </v-list-item-avatar>
              <v-col cols="3">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-if="item.documentcategories_id !== 0"
                    v-text="item.documentcategories_id"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="
                      moment(item.date_creation, 'YYYY-MM-Do HH:mm ss').format(
                        ' Do MMM YYYY, HH:mm'
                      )
                    "
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
              <v-col cols="7">
                <v-list-item-content>
                  <v-list-item-title v-text="item.comment"></v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-list-item-icon>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="openFile(item)"
                  :title="$t('assetDetails.text2')"
                >
                  <v-icon dark>mdi-file-eye</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-icon>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="downloadFile(item)"
                  :title="$t('assetDetails.text3')"
                >
                  <v-icon dark>mdi-cloud-download-outline</v-icon>
                </v-btn>
              </v-list-item-icon>
            </template>
          </v-list-item>
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "../../plugins/moment";
import USER_API from "../../api/user";

export default {
  props: {
    loadingDoc: {
      type: Boolean,
      deafault: false
    },
    docList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      alert: false,
      alertMessage: "",
      alertType: "success"
    };
  },
  computed: {
    ...mapGetters(["getActiveEntity"]),
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    moment,
    documentIcon(mimeType) {
      if (mimeType === "image/jpeg") {
        return "mdi-file-image";
      } else if (mimeType === "application/pdf") {
        return "mdi-file-document";
      } else if (mimeType === "video/mp4") {
        return "mdi-file-video";
      } else {
        return "mdi-file";
      }
    },
    downloadFile(item) {
      USER_API.downloadDocument(item);
    },
    openFile(item) {
      USER_API.openDocument(item);
    }
  }
};
</script>
