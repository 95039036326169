<template>
  <default-view>
    <v-row
      align="start"
      no-gutters
      justify="center"
      class="fill-height mt-n12 mx-n3"
    >
      <v-col cols="12">
        <tuto-support> </tuto-support>
      </v-col>
    </v-row>
  </default-view>
</template>
<script>
import tutoSupport from "../components/support/Tuto.vue";
import DefaultView from "../components/DefaultView";

export default {
  components: { tutoSupport, DefaultView }
};
</script>
